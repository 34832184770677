<template>
    <div
      class="input-options"
      v-click-outside="closeOptions"
      :class="{
        'right-margin-1': activeInput === 'eixoDireito' || activeInput === 'eixoEsquerdo' || activeInput === 'BasePrismaticasOlhoDireito' || activeInput === 'BasePrismaticasOlhoEsquerdo',
        'right-margin-2': activeInput === 'DPDireito' || activeInput === 'DPEsquerdo' || activeInput === 'AVDireito' || activeInput === 'AVEsquerdo',
        'right-margin-3': activeInput === 'AdicaoDireito' || activeInput === 'AdicaoEsquerdo',
      }"
    >
      <table >
        <tr v-for="(row, rowIndex) in options" :key="rowIndex">
            <td v-for="(option, colIndex) in row" :key="colIndex" @click="setValue(option)"
            :class="{'bordered': (rowIndex != 0 || colIndex !=0) && (rowIndex != 0 || colIndex != option.length-1) && (rowIndex != options.length-1 || colIndex != 0) && (rowIndex != options.length-1 || colIndex != option.length-1)}">
                {{ option }}
            </td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {
    props: {
        form: Object,
        options: Array,
        activeInput: String,
        setActiveInput: Function,
    },

    computed: {
      hasFormPerto: function () { return !!document.getElementById("form-perto-apos-refracao")},
      hasFormLonge: function () { return !!document.getElementById("form-longe-apos-refracao")},
      hasFormPertoAvppcc: function () { return !!document.getElementById("form-longe-avppcc")},
      hasFormPertoAvppsc: function () { return !!document.getElementById("form-longe-avppsc")},
    },

    methods: {
      closeOptions(){
        this.setActiveInput(null)
      },

      setValue(value) {
        let key = null
        if(this.form.id.includes('acuidade-visual')) {

          if(this.activeInput.includes('Direito'))
            key = 'olhoDireito'
          else if (this.activeInput.includes('Esquerdo'))
            key = 'olhoEsquerdo'
        } else if(this.form.name === 'Refração Subjetiva Dinâmica'){
          if(this.activeInput === 'esfericoDireito')
            key = 'olhoDireitoEsfericoDin'
          else if(this.activeInput === 'cilindricoDireito')
            key = 'olhoDireitoCilindricoDin'
          else if(this.activeInput === 'eixoDireito')
            key = 'olhoDireitoEixoDin'
          else if(this.activeInput === 'DPDireito')
            key = 'olhoDireitoDPDin'
          else if(this.activeInput === 'AVDireito')
            key = 'olhoDireitoAVDin'
          else if(this.activeInput === 'AdicaoDireito')
            key = 'olhoDireitoAdicaoDin'

          else if(this.activeInput === 'esfericoEsquerdo')
            key = 'olhoEsquerdoEsfericoDin'
          else if(this.activeInput === 'cilindricoEsquerdo')
            key = 'olhoEsquerdoCilindricoDin'
          else if(this.activeInput === 'eixoEsquerdo')
            key = 'olhoEsquerdoEixoDin'
          else if(this.activeInput === 'DPEsquerdo')
            key = 'olhoEsquerdoDPDin'
          else if(this.activeInput === 'AVEsquerdo')
            key = 'olhoEsquerdoAVDin'
          else if(this.activeInput === 'AdicaoEsquerdo')
            key = 'olhoEsquerdoAdicaoDin'

        } else if(this.form.name === 'Campo visual'){
          if(this.activeInput === 'mdDireito')
              key = 'olhoDireitoMd'
          else if(this.activeInput === 'psdDireito')
              key = 'olhoDireitoPsd'

          else if(this.activeInput === 'mdEsquerdo')
              key = 'olhoEsquerdoMd'
          else if(this.activeInput === 'psdEsquerdo')
              key = 'olhoEsquerdoPsd'

        }else if(this.form.name === 'Refração Subjetiva Estática'){
          if(this.activeInput === 'esfericoDireito')
            key = 'olhoDireitoEsfericoEst'
          else if(this.activeInput === 'cilindricoDireito')
            key = 'olhoDireitoCilindricoEst'
          else if(this.activeInput === 'eixoDireito')
            key = 'olhoDireitoEixoEst'
          else if(this.activeInput === 'DPDireito')
            key = 'olhoDireitoDPEst'
          else if(this.activeInput === 'AVDireito')
            key = 'olhoDireitoAVEst'
          else if(this.activeInput === 'AdicaoDireito')
            key = 'olhoDireitoAdicaoEst'

          else if(this.activeInput === 'esfericoEsquerdo')
            key = 'olhoEsquerdoEsfericoEst'
          else if(this.activeInput === 'cilindricoEsquerdo')
            key = 'olhoEsquerdoCilindricoEst'
          else if(this.activeInput === 'eixoEsquerdo')
            key = 'olhoEsquerdoEixoEst'
          else if(this.activeInput === 'DPEsquerdo')
            key = 'olhoEsquerdoDPEst'
          else if(this.activeInput === 'AVEsquerdo')
            key = 'olhoEsquerdoAVEst'
          else if(this.activeInput === 'AdicaoEsquerdo')
            key = 'olhoEsquerdoAdicaoEst'

        } else {
            if(this.activeInput === 'esfericoDireito')
              key = 'olhoDireitoEsferico'
            else if(this.activeInput === 'cilindricoDireito')
              key = 'olhoDireitoCilindrico'
            else if(this.activeInput === 'eixoDireito')
              key = 'olhoDireitoEixo'
            else if(this.activeInput === 'DPDireito')
              key = 'olhoDireitoDP'
            else if(this.activeInput === 'AdicaoDireito')
              key = 'olhoDireitoAdicao'
            else if(this.activeInput === 'acuidadeVisualDireito')
              key = 'olhoDireitoAV'
            else if(this.activeInput === 'acuidadeVisualRigidaDireito')
              key = 'olhoDireitoAVRigida'
            else if(this.activeInput === 'basePrismaticasOlhoDireito')
              key = 'olhoDireitoBasePrismaticas'

            else if(this.activeInput === 'esfericoEsquerdo')
              key = 'olhoEsquerdoEsferico'
            else if(this.activeInput === 'cilindricoEsquerdo')
              key = 'olhoEsquerdoCilindrico'
            else if(this.activeInput === 'eixoEsquerdo')
              key = 'olhoEsquerdoEixo'
            else if(this.activeInput === 'DPEsquerdo')
              key = 'olhoEsquerdoDP'
            else if(this.activeInput === 'AdicaoEsquerdo')
              key = 'olhoEsquerdoAdicao'
            else if(this.activeInput === 'acuidadeVisualEsquerdo')
              key = 'olhoEsquerdoAV'
            else if(this.activeInput === 'acuidadeVisualRigidaEsquerdo')
              key = 'olhoEsquerdoAVRigida'
            else if(this.activeInput === 'basePrismaticasOlhoEsquerdo')
              key = 'olhoEsquerdoBasePrismaticas'
        }

        this.$emit('select', { key , value })
        this.$emit('blur')

        setTimeout(() => {
          this.setActiveInput(null)
        }, 200)
      },
    }

}
</script>

<style lang="scss" scoped>
.input-options {
    background-color: #F8F8FD;
    width: 500px;
    position: absolute !important;
    z-index: 150;
    border: 1.5px solid var(--blue-500) !important;
    border-radius: 8px !important;

    table {
        width: 100%;
        height: 100%;
        border-radius: 8px !important;
        td {
            text-align: center;
            padding: 5px;
            cursor: pointer;
            transition: .5s;

            &:hover {
                background-color: var(--blue-100);
                transition: .5s;
            }
        }

        .bordered {
            border: 1.5px solid var(--blue-100);
        }
    }


}
.right-margin-1 {
  margin-left: -8vw;
}

.right-margin-2 {
  margin-left: -20vw;
}

.right-margin-3 {
  margin-left: -25vw;
}

@media(max-width: 1400px){
  .right-margin-1 {
    margin-left: -13vw;
  }

  .right-margin-2 {
    margin-left: -25vw;
  }

  .right-margin-3 {
    margin-left: -27vw;
  }

}

@media(max-width: 1300px){
  .right-margin-1 {
    margin-left: -16vw;
  }

  .right-margin-2 {
    margin-left: -30vw;
  }

  .right-margin-3 {
    margin-left: -30vw;
  }

}

</style>
